import { useParams } from "react-router";
import "./grid.scss";

export const Grid = () => {
  const { reportId } = useParams();
  return (
    <div data-testid="grid" className="grid">
      <iframe
        src={`https://panelweb-dev.pl.auchan.com/wsgrid/Auth?reportName=030_001&arg1=${reportId}`}
      ></iframe>
    </div>
  );
};
