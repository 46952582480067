import React from "react";
import "./LeftMenu.scss";
import { UserSection } from "./UserSection/UserSection";
import { MenuTree } from "./MenuTree/MenuTree";

export const LeftMenu = () => {
  return (
    <div data-testid="left-menu" className="left-menu">
      <UserSection />
      <MenuTree />
    </div>
  );
};
