import React, { useEffect } from "react";
import "./App.scss";
import { TopMenu } from "./components/TopMenu/TopMenu";
import { LeftMenu } from "./components/LeftMenu/LeftMenu";
import { Content } from "./components/Content/Content";
import axios from "axios";
import { API_URL } from "./env";
import { useDispatch, useSelector } from "react-redux";
import {
  setApplicant,
  setGridAdmin,
} from "./features/applicant/applicantSlice";
import { setWorkers } from "./features/workers/workersSlice";
import { useNavigate } from "react-router";
import {
  setChoosenLocalization,
  setLocalizations,
} from "./features/localizations/localizationsSlice";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const dispatch = useDispatch();
  const applicant = useSelector((state: any) => state.applicant.applicant);
  const navigate = useNavigate();

  const choosenLocalization = useSelector(
    (state: any) => state.localizations.choosenLocalization
  );

  useEffect(() => {
    // get localizations
    axios.get(API_URL + "/Identity/getLocalisations").then((res) => {
      dispatch(setLocalizations(res.data));
      dispatch(setChoosenLocalization(res.data[0]));
    });

    axios.get(API_URL + "/Identity/getIdentity").then((res) => {
      dispatch(setApplicant(res.data));
      dispatch(setGridAdmin(res.data.gridAdmin));
    });

    navigate("/dashboard");
  }, []);

  useEffect(() => {
    if (applicant && choosenLocalization) {
      const body = {
        applicantPOL: applicant.applicantId,
        localisation: {
          auchancenaffcode: choosenLocalization.auchancenaffcode,
          office: choosenLocalization.office,
        },
      };
      axios.post(API_URL + `/GetEmployeesApplications`, body).then((res) => {
        dispatch(setWorkers(res.data.employeeApplications));
      });
    }
  }, [choosenLocalization]);

  return (
    <div data-testid="app" className="App">
      {/* TopMenu */}
      <TopMenu />
      <div className="main-app">
        <LeftMenu />
        <Content />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
}

export default App;
