import { Logo } from "./Logo/Logo";
import { Toolbar } from "./Toolbar/Toolbar";
import "./TopMenu.scss";
import { TopPDF } from "./TopPDF/TopPDF";

export const TopMenu = () => {
  return (
    <div data-testid="top-menu" className="top-menu">
      <Logo />
      <TopPDF />
      <Toolbar />
    </div>
  );
};
