import { configureStore } from "@reduxjs/toolkit";
import modalReducer from "../features/modal/modalSlice";
import applicationReducer from "../features/applications/applicationsSlice";
import languagesReducer from "../features/languages/languagesSlice";
import formRequestReducer from "../features/formRequest/formRequestSlice";
import statusesReducer from "../features/statuses/statusesSlice";
import workersReducer from "../features/workers/workersSlice";
import lastVisitedReducer from "../features/lastVisited/lastVisitedSlice";
import applicantReducer from "../features/applicant/applicantSlice";
import appReducer from "../features/app/appSlice";
import localizationsReducer from "../features/localizations/localizationsSlice";

export const store = configureStore({
  reducer: {
    modal: modalReducer,
    applications: applicationReducer,
    languages: languagesReducer,
    formRequest: formRequestReducer,
    statuses: statusesReducer,
    workers: workersReducer,
    lastVisited: lastVisitedReducer,
    applicant: applicantReducer,
    app: appReducer,
    localizations: localizationsReducer,
  },
});
