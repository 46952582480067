import { useDispatch, useSelector } from "react-redux";
import "./Language.scss";
import ReactCountryFlag from "react-country-flag";
import { setLanguageId } from "../../../../../../features/formRequest/formRequestSlice";

interface LanguageProps {
  language: any;
}

export const Language = ({ language }: LanguageProps) => {
  const dispatch = useDispatch();
  const formRequestState = useSelector((state: any) => state.formRequest);

  return (
    <div
      data-testid="language"
      className={`language-container ${
        formRequestState.formRequest.languageId === language.languageId ||
        (formRequestState.formMode === "show" &&
          formRequestState.choosenRequest.applicationBody.languageId ===
            language.languageId)
          ? "active-language-container"
          : ""
      }`}
      onClick={() => {
        dispatch(setLanguageId(language.languageId));
      }}
    >
      <ReactCountryFlag
        countryCode={language.languageId}
        svg
        style={{ fontSize: "30px" }}
      />
      {language.languageName}
    </div>
  );
};
