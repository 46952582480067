import "./Content.scss";
import { Modal } from "../../ui/elements/Modal/Modal";
import { NewRequestModal } from "./Dashboard/NewRequestModal/NewRequestModal";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

export const Content = () => {
  const modalState = useSelector((state: any) => state.modal);
  const choosenRequest = useSelector(
    (state: any) => state.formRequest.choosenRequest
  );
  const formRequest = useSelector((state: any) => state.formRequest);
  return (
    <div data-testid="content" className="content">
      <Outlet />
      {modalState.show && (
        <Modal
          title={`${
            choosenRequest
              ? `Wniosek #${choosenRequest.applicationId} - ${choosenRequest.applicationBody.employee.firstName} ${choosenRequest.applicationBody.employee.lastName}`
              : `Tworzenie wniosku dla pracownika - ${formRequest.formRequest.employee.firstName} ${formRequest.formRequest.employee.lastName}`
          }`}
        >
          <NewRequestModal />
        </Modal>
      )}
    </div>
  );
};
