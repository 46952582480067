import { useEffect } from "react";
import "./FormRequest.scss";
import { Language } from "./Language/Language";
import { Level } from "./Level/Level";
import { API_URL } from "../../../../../env";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setLanguages } from "../../../../../features/languages/languagesSlice";
import { hideModal } from "../../../../../features/modal/modalSlice";
import {
  setSuggestions,
  setLearningModeId,
  setContinuation,
  setStartDate,
  setBussinessClarification,
  setBussinessClarificationText,
  clearState,
  setLearnHistory,
  setLevel,
} from "../../../../../features/formRequest/formRequestSlice";
import { setStatuses } from "../../../../../features/statuses/statusesSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  HiOutlineUserGroup,
  HiOutlineUser,
  HiOutlineSpeakerphone,
} from "react-icons/hi";
import { toast } from "react-toastify";

interface FormRequestProps {
  choosenRequest: any;
}

export const FormRequest = ({ choosenRequest }: FormRequestProps) => {
  const dispatch = useDispatch();
  const languages = useSelector((state: any) => state.languages.languages);
  const navigate = useNavigate();
  const formRequestState = useSelector((state: any) => state.formRequest);
  const choosenRequestState = formRequestState.choosenRequest;
  const applicant = useSelector((state: any) => state.applicant.applicant);
  const choosenLocalization = useSelector(
    (state: any) => state.localizations.choosenLocalization
  );

  const getLastRequest = () => {
    console.log("getLastApplication");
  };

  const getPercentageFilled = () => {
    let filled = 0;
    if (formRequestState.formRequest.languageId !== null) {
      filled += 10;
    }
    if (formRequestState.formRequest.isContinuation !== null) {
      filled += 15;
    }
    if (formRequestState.formRequest.learningModeId !== null) {
      filled += 15;
    }
    if (formRequestState.formRequest.sendedBefore !== null) {
      filled += 15;
    }
    if (formRequestState.formRequest.startDate !== null) {
      filled += 15;
    }
    if (formRequestState.formRequest.buisnessClarification !== null) {
      filled += 15;
    }
    if (formRequestState.formRequest.level !== null) {
      filled += 15;
    }
    return filled;
  };

  useEffect(() => {
    axios.post(API_URL + "/Languages/getLanguages", {}).then((res) => {
      dispatch(setLanguages(res.data.languages));
    });

    axios.post(API_URL + "/Statuses/getStatuses", {}).then((res) => {
      dispatch(setStatuses(res.data.statuses));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendFormRequest = () => {
    const headers = {
      "Content-Type": "application/json",
    };
    const body = {
      application: {
        applicationId: 0,
        applicationStatusId: 1,
        applicant: applicant.applicantId,
        employee: formRequestState.employee,
        applicationBody: {
          employee: formRequestState.formRequest.employee,
          languageId: formRequestState.formRequest.languageId,
          startDate: formRequestState.formRequest.startDate,
          sendedBefore: formRequestState.formRequest.sendedBefore,
          suggestions: formRequestState.formRequest.suggestions,
          buisnessClarification: {
            buisnessClarificationId:
              formRequestState.formRequest.buisnessClarification,
            buisnessClarification:
              formRequestState.formRequest.bussinessClarificationText || "",
          },
          learningMode: {
            learningModeId: formRequestState.formRequest.learningModeId,
            learningMode: "string",
          },
          isContinuation: formRequestState.formRequest.isContinuation,
          learnHistory: formRequestState.formRequest.learnHistory,
          level: formRequestState.formRequest.level,
        },
      },
    };
    axios
      .post(API_URL + "/Applications/saveApplication", JSON.stringify(body), {
        headers: headers,
      })
      .then((res) => {
        if (res.data.status.success) {
          dispatch(hideModal());
          dispatch(clearState());
          navigate("/requests");
        } else {
          toast(res.data.status.message, {
            type: "error",
          });
        }
      });
  };

  return (
    <div data-testid="form-request" className="form-request">
      <div className="first-row-list">
        <div style={{ width: "50%" }}>
          <div className="title">Wybierz język:</div>
          <div className="language-list">
            {languages && languages.length > 0
              ? languages.map((language: any, key: number) => {
                  return <Language key={key} language={language} />;
                })
              : ""}
          </div>
        </div>
        <div style={{ width: "50%" }}>
          <div className="title">Forma nauki:</div>
          <div className="language-list">
            <div
              className={`language-container ${
                formRequestState.formRequest.learningModeId === 1
                  ? "active-language-container"
                  : ""
              }
              
              ${
                choosenRequestState?.applicationBody?.learningMode &&
                choosenRequestState?.applicationBody?.learningMode
                  ?.learningModeId === 1
                  ? "active-language-container"
                  : ""
              }`}
              onClick={() => {
                dispatch(setLearningModeId(1));
                getPercentageFilled();
              }}
            >
              <div>
                <HiOutlineUser size={27} color={"#666"} />
              </div>
              <div>Indywidualne</div>
            </div>
            <div
              className={`language-container \
              ${
                formRequestState.formRequest.learningModeId === 2
                  ? "active-language-container"
                  : ""
              }

              ${
                choosenRequestState?.applicationBody?.learningMode &&
                choosenRequestState?.applicationBody?.learningMode
                  ?.learningModeId === 2
                  ? "active-language-container"
                  : ""
              }
              
              `}
              onClick={() => {
                dispatch(setLearningModeId(2));
                getPercentageFilled();
              }}
            >
              <div>
                <HiOutlineUserGroup size={27} color={"#666"} />
              </div>
              <div>Grupowe</div>
            </div>
            {/* if languageId = UK */}
            {formRequestState.formRequest.languageId === "GB" ||
            choosenRequestState?.applicationBody?.languageId ? (
              <div
                className={`language-container ${
                  formRequestState.formRequest.learningModeId === 3
                    ? "active-language-container"
                    : ""
                }
              
              ${
                choosenRequestState?.applicationBody?.learningMode &&
                choosenRequestState?.applicationBody?.learningMode
                  ?.learningModeId === 3
                  ? "active-language-container"
                  : ""
              }`}
                onClick={() => {
                  dispatch(setLearningModeId(3));
                  getPercentageFilled();
                }}
              >
                <div>
                  <HiOutlineSpeakerphone size={27} color={"#666"} />
                </div>
                <div>Konwersacje</div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="second-row">
        <div>
          <div className="title">Poziom:</div>
          <div className="level-list-2">
            <div style={{ display: "flex" }}>
              <div
                style={{ flex: 1 }}
                onClick={() => {
                  dispatch(setLevel("A1"));
                }}
              >
                <Level
                  level="A1"
                  type="level"
                  active={
                    formRequestState.formRequest.level === "A1" ||
                    choosenRequestState?.applicationBody?.level === "A1"
                  }
                />
              </div>
              <div
                style={{ flex: 1 }}
                onClick={() => {
                  dispatch(setLevel("A2"));
                }}
              >
                <Level
                  level="A2"
                  type="level"
                  active={
                    formRequestState.formRequest.level === "A2" ||
                    choosenRequestState?.applicationBody?.level === "A2"
                  }
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div
                style={{ flex: 1 }}
                onClick={() => {
                  dispatch(setLevel("B1"));
                }}
              >
                <Level
                  level="B1"
                  type="level"
                  active={
                    formRequestState.formRequest.level === "B1" ||
                    choosenRequestState?.applicationBody?.level === "B1"
                  }
                />
              </div>
              <div
                style={{ flex: 1 }}
                onClick={() => {
                  dispatch(setLevel("B2"));
                }}
              >
                <Level
                  level="B2"
                  type="level"
                  active={
                    formRequestState.formRequest.level === "B2" ||
                    choosenRequestState?.applicationBody?.level === "B2"
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="title">Tryb nauki:</div>
          <div className="continuation-list">
            <div
              onClick={() => {
                dispatch(setContinuation(true));
                getLastRequest();
              }}
            >
              <Level
                level="Kontynuacja nauki"
                type="continuation"
                active={
                  formRequestState.formRequest.isContinuation ||
                  choosenRequestState?.applicationBody?.isContinuation
                }
              />
            </div>
            <div
              onClick={() => {
                dispatch(setContinuation(false));
              }}
            >
              <Level
                level="Zgłoszenie nowego kursanta (potrzebny test poziomujący)"
                type="continuation"
                active={
                  formRequestState.formRequest.isContinuation === false ||
                  choosenRequestState?.applicationBody?.isContinuation === false
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className="question-row">
        <div className="question-col">
          <div className="title">
            Czy pracownik kiedyś korzystał z nauki języka w ARP?
          </div>
          <div className="level-list">
            <Level level="TAK" type="sendedBefore" />

            <Level level="NIE" type="sendedBefore" />
          </div>
          {formRequestState.formRequest.sendedBefore === true ||
          choosenRequestState?.applicationBody?.sendedBefore === true ? (
            <div className="textareas">
              <textarea
                placeholder="Opisz krótko historię jego nauki"
                defaultValue={
                  formRequestState.formRequest.learnHistory ||
                  choosenRequestState?.applicationBody?.learnHistory
                }
                onChange={(e) => {
                  dispatch(setLearnHistory(e.target.value));
                }}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="question-col">
          <div className="title">Wybierz czas rozpoczęcia nauki:</div>
          <div className="level-list">
            <div
              onClick={() => {
                dispatch(setStartDate("Od Września"));
              }}
            >
              <Level
                level="Od Września"
                type="date"
                active={
                  formRequestState.formRequest.startDate === "Od Września" ||
                  choosenRequestState?.applicationBody?.startDate ===
                    "Od Września"
                }
              />
            </div>
            <div
              onClick={() => {
                dispatch(setStartDate("Od Lutego"));
              }}
            >
              <Level
                level="Od Lutego"
                type="date"
                active={
                  formRequestState.formRequest.startDate === "Od Lutego" ||
                  choosenRequestState?.applicationBody?.startDate ===
                    "Od Lutego"
                }
              />
            </div>
          </div>
          <div
            onClick={() => {
              dispatch(setStartDate("Najszybciej jak to możliwe"));
            }}
          >
            <Level
              level="Najszybciej jak to możliwe"
              type="date"
              active={
                formRequestState.formRequest.startDate ===
                  "Najszybciej jak to możliwe" ||
                choosenRequestState?.applicationBody?.startDate ===
                  "Najszybciej jak to możliwe"
              }
            />
          </div>
        </div>
      </div>

      {/* Uzasadnienie biznesowe */}
      <div className="reason">
        <div className="title">Uzasadnienie biznesowe:</div>
        <div
          onClick={() => {
            dispatch(setBussinessClarification(1));
          }}
        >
          <Level
            level="Pracownik na co dzień musi komunikować się w języku obcym."
            type="reason"
            active={
              formRequestState.formRequest.buisnessClarification === 1 ||
              choosenRequestState?.applicationBody?.buisnessClarification
                ?.buisnessClarificationId === 1
            }
          />
        </div>
        <div
          onClick={() => {
            dispatch(setBussinessClarification(2));
          }}
        >
          <Level
            level="Pracownik bierze udział w międzynarodowych projektach wymagających znajomości języka."
            type="reason"
            active={
              formRequestState.formRequest.buisnessClarification === 2 ||
              choosenRequestState?.applicationBody?.buisnessClarification
                ?.buisnessClarificationId === 2
            }
          />
        </div>
        <div
          onClick={() => {
            dispatch(setBussinessClarification(3));
          }}
        >
          <Level
            level="Pracownik przygotowuje się do realizacji zadań w języku obcym w najbliższej przyszłości."
            type="reason"
            active={
              formRequestState.formRequest.buisnessClarification === 3 ||
              choosenRequestState?.applicationBody?.buisnessClarification
                ?.buisnessClarificationId === 3
            }
          />
        </div>
        <div
          onClick={() => {
            dispatch(setBussinessClarification(4));
          }}
        >
          <Level
            level="Inne"
            type="reason"
            active={
              formRequestState.formRequest.buisnessClarification === 4 ||
              choosenRequestState?.applicationBody?.buisnessClarification
                ?.buisnessClarificationId === 4
            }
          />
          {formRequestState.formRequest.buisnessClarification === 4 ||
          choosenRequestState?.applicationBody?.buisnessClarification
            ?.buisnessClarificationId === 4 ? (
            <div className="textareas">
              <div className="title">Wpisz inne uzasadnienie biznesowe:</div>
              <textarea
                defaultValue={
                  choosenRequestState?.applicationBody?.buisnessClarification
                    .buisnessClarification
                }
                placeholder="Uzasadnienie biznesowe..."
                onChange={(e) => {
                  if (e.target.value.length >= 1) {
                    dispatch(setBussinessClarificationText(e.target.value));
                  } else {
                    dispatch(setBussinessClarificationText(null));
                  }
                }}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="textareas">
        <div className="title">Sugestie:</div>
        <textarea
          defaultValue={
            formRequestState.formMode === "show"
              ? formRequestState.choosenRequest.applicationBody.suggestions
              : ""
          }
          placeholder="Sugestie..."
          onChange={(e) => {
            if (e.target.value.length >= 1) {
              dispatch(setSuggestions(e.target.value));
            } else {
              dispatch(setSuggestions(null));
            }
          }}
        ></textarea>
      </div>

      <div className="button-box">
        {getPercentageFilled() === 100 ? (
          <button
            onClick={() => {
              sendFormRequest();
            }}
          >
            Wyślij
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
