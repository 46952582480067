import { createSlice } from "@reduxjs/toolkit";

export const localizationsSlice = createSlice({
  name: "localizations",
  initialState: {
    localizations: [],
    choosenLocalization: null,
  },
  reducers: {
    setLocalizations: (state, action) => {
      state.localizations = action.payload;
    },
    setChoosenLocalization: (state, action) => {
      state.choosenLocalization = action.payload;
    },
  },
});

export const { setLocalizations, setChoosenLocalization } =
  localizationsSlice.actions;
export default localizationsSlice.reducer;
