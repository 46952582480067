import React from "react";
import "./Avatar.scss";

interface AvatarProps {
  width: number;
  height: number;
  theme: "avatar-light" | "avatar-dark";
  initials: string;
  backgroundColor?: string;
}

export const Avatar = ({
  width,
  height,
  theme,
  initials,
  backgroundColor,
}: AvatarProps) => {
  return (
    <div
      data-testid="avatar"
      className={`avatar ${theme}`}
      style={{ width: width, height: height, backgroundColor: backgroundColor }}
    >
      <div className="initials">{initials}</div>
    </div>
  );
};
