import { MoreIcon } from "../../../../ui/icons/MoreIcon";
import "./InfoStat.scss";
import { useNavigate } from "react-router";

interface IInfoStatProps {
  title: string;
  desc: string;
  number: number;
  progress?: string;
  color?: string;
  link: string;
}

export const InfoStat = ({
  title,
  desc,
  number,
  progress,
  color,
  link,
}: IInfoStatProps) => {
  const navigate = useNavigate();
  const redirectTo = (link: string) => {
    if (link === "workers") {
      navigate("/workers");
    } else if (link === "applications") {
      navigate("/requests?status=InProgress");
    } else if (link === "applicationsInProgress") {
      navigate("/requests?status=LearningInProgress");
    }
  };

  return (
    <div
      data-testid="info-stat"
      className="info-stat"
      onClick={() => {
        redirectTo(link);
      }}
    >
      <div className="info-left">
        <div className="title">{title}</div>
        <div className="info-desc">{desc}</div>
      </div>
      <div className="info-right">
        <div className="info-value">
          <div>{number}</div>
          <div style={{ color: color }} className="percent">
            {progress ? progress : ""}
          </div>
        </div>
      </div>
      <div className="info-more">
        <MoreIcon color="#29313e" width={11} height={24} />
      </div>
    </div>
  );
};
