import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    dashboard: {
      boxes: [],
    },
    activeMenu: "dashboard",
    history: [],
    rulesLink: "",
  },
  reducers: {
    setDashboardBoxes: (state, action) => {
      state.dashboard.boxes = action.payload;
    },
    setActiveMenu: (state, action) => {
      state.activeMenu = action.payload;
    },
    setHistory: (state, action) => {
      state.history = action.payload;
    },
    setRulesLink: (state, action) => {
      state.rulesLink = action.payload;
    },
  },
});

export const { setDashboardBoxes, setActiveMenu, setHistory, setRulesLink } =
  appSlice.actions;
export default appSlice.reducer;
