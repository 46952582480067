import { Link } from "react-router-dom";
import { ProfileIcon } from "../../../ui/icons/ProfileIcon";
import { MenuItem } from "./MenuItem/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { setActiveMenu } from "../../../features/app/appSlice";
import { HiOutlineDocumentText } from "react-icons/hi";
import { AiOutlineCheckCircle, AiOutlineFileAdd } from "react-icons/ai";

export const MenuTree = () => {
  const app = useSelector((state: any) => state.app);
  const dispatch = useDispatch();
  const childrensRequests = [
    {
      name: "Aktualne",
      path: "/grid/1",
    },
    {
      name: "Archiwalne",
      path: "/grid/2",
    },
    {
      name: "Wszystkie",
      path: "/grid/3",
    },
  ];

  const isGridAdmin = useSelector((state: any) => state.app.isGridAdmin);
  return (
    <div data-testid="menu-tree" className="menu-tree">
      <Link
        to="/dashboard"
        onClick={() => {
          dispatch(setActiveMenu("dashboard"));
        }}
      >
        <MenuItem
          name="Dashboard"
          active={app.activeMenu === "dashboard" ? true : false}
          icon={<ProfileIcon color="#eaeaea" width={21} height={21} />}
        />
      </Link>
      <Link
        to="/workers"
        onClick={() => {
          dispatch(setActiveMenu("workers"));
        }}
      >
        <MenuItem
          name="Złóż wniosek"
          icon={<AiOutlineFileAdd color="#eaeaea" width={22} height={22} />}
          active={app.activeMenu === "workers" ? true : false}
        />
      </Link>
      <Link
        to="/requests"
        onClick={() => {
          dispatch(setActiveMenu("requests"));
        }}
      >
        <MenuItem
          name="Wnioski"
          icon={
            <HiOutlineDocumentText color="#eaeaea" width={22} height={22} />
          }
          active={app.activeMenu === "requests" ? true : false}
        />
      </Link>
      {isGridAdmin ? (
        <Link to="#">
          <MenuItem
            name="Rozpatrywanie wniosków"
            icon={
              <AiOutlineCheckCircle color="#eaeaea" width={21} height={21} />
            }
            childrens={childrensRequests}
          />
        </Link>
      ) : (
        ""
      )}
    </div>
  );
};
