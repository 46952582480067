import "./WorkersContainer.scss";
import { WorkerItem } from "./WorkerItem/WorkerItem";
import { useSelector } from "react-redux";

export const WorkersContainer = () => {
  const workers = useSelector((state: any) => state.workers.workers);

  return (
    <div data-testid="workers-container" className="workers-container">
      <div className="title">Pracownicy</div>
      <div className="workers-list">
        {workers.map((employee: any, key: number) => {
          return (
            <WorkerItem
              key={key}
              id={employee.cn || ""}
              firstName={employee.givenname || ""}
              lastName={employee.sn || ""}
              title={employee.title || ""}
              applications={employee.applications || []}
              mail={employee.mail || ""}
              level={0}
              childrens={employee.empl || []}
              collapsed={true}
              info={employee.info || ""}
              info2={employee.info2 || ""}
            />
          );
        })}
      </div>
    </div>
  );
};
