import { createSlice } from "@reduxjs/toolkit";

export const workersSlice = createSlice({
  name: "workers",
  initialState: {
    workers: [],
  },
  reducers: {
    setWorkers: (state, action) => {
      state.workers = action.payload;
    },
  },
});

export const { setWorkers } = workersSlice.actions;
export default workersSlice.reducer;
