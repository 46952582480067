export const ItemTriangle = () => {
  return (
    <svg
      width="9"
      height="12"
      viewBox="0 0 9 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 6L0 11.1962V0.803848L9 6Z" fill="black" fill-opacity="0.1" />
    </svg>
  );
};
