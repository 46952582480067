import "./WorkersContainer.scss";
import { WorkerItem } from "../../Workers/WorkersContainer/WorkerItem/WorkerItem";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const WorkersContainer = () => {
  const workers = useSelector((state: any) => state.workers.workers);
  return (
    <div data-testid="workers-container" className="workers-container">
      <div className="title">Pracownicy</div>

      <div className="workers-list">
        {workers.map((item: any, key: number) => {
          if (key > 4) {
            return null;
          }
          if (item) {
            return (
              <WorkerItem
                key={key}
                id={item.cn}
                firstName={item.givenname}
                lastName={item.sn}
                title={item.title}
                applications={item.applications}
                mail={item.mail}
                level={0}
                childrens={item.empl}
                collapsed={true}
                info={item.info}
                info2={item.info2}
              />
            );
          } else {
            return null;
          }
        })}
        <div className="show-more">
          <Link to="/workers">(zobacz wszystkich)</Link>
        </div>
      </div>
    </div>
  );
};
