import "./Level.scss";
import {
  setLanguageLevel,
  setSendedBefore,
} from "../../../../../../features/formRequest/formRequestSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

interface LevelProps {
  level: string;
  type: string;
  active?: boolean;
}

export const Level = ({ level, type, active = false }: LevelProps) => {
  const dispatch = useDispatch();
  const formRequestState = useSelector((state: any) => state.formRequest);

  const updateFormRequest = (type: string, value: string) => {
    if (type === "level") {
      dispatch(setLanguageLevel(value));
    } else if (type === "sendedBefore") {
      if (value === "TAK") {
        dispatch(setSendedBefore(true));
      } else if (value === "NIE") {
        dispatch(setSendedBefore(false));
      }
    }
  };

  return (
    <div
      data-testid="level"
      className={`level-container ${
        formRequestState.formRequest.level === level
          ? "level-container-active"
          : ""
      } 
      
      ${
        formRequestState.formRequest.sendedBefore === true && level === "TAK"
          ? "level-container-active"
          : ""
      }
      
      ${
        formRequestState.formRequest.sendedBefore === false && level === "NIE"
          ? "level-container-active"
          : ""
      }

      ${
        formRequestState.formMode === "show" &&
        formRequestState.choosenRequest.applicationBody.level === level
          ? "level-container-active"
          : ""
      }

      ${
        formRequestState.formMode === "show" &&
        formRequestState.choosenRequest.applicationBody.sendedBefore === true &&
        level === "TAK"
          ? "level-container-active"
          : ""
      }

      ${
        formRequestState.formMode === "show" &&
        formRequestState.choosenRequest.applicationBody.sendedBefore ===
          false &&
        level === "NIE"
          ? "level-container-active"
          : ""
      }

      ${active ? "level-container-active" : ""}
      
      `}
      onClick={() => {
        updateFormRequest(type, level);
      }}
    >
      {level}
    </div>
  );
};
