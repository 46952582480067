interface CheckIconProps {
  width: number;
  height: number;
  color: string;
}

export const CheckIcon = ({ width, height, color }: CheckIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.8333 9.16667L14.0625 30.8333L4.16663 20.985"
        stroke={color}
        stroke-width="4"
        stroke-linecap="round"
      />
    </svg>
  );
};
