import "./HistoryItemsRight.scss";
import { ItemTriangle } from "./ItemTriangle";
import ReactCountryFlag from "react-country-flag";

interface PropsInterface {
  text: string;
  flag: string;
}

export const HistoryItemsRight = ({ text, flag }: PropsInterface) => {
  return (
    <div data-testid="history-item-right" className="history-item-right">
      <div className="timeline-item">
        <div className="timeline-item-content">
          <div className="circle-lang">
            <ReactCountryFlag
              countryCode={flag}
              style={{ width: "50px", height: "50px", borderRadius: "50%" }}
              svg
            />
          </div>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
      </div>
      <div className="timeline-dot"></div>
      <div className="item-triangle">
        <ItemTriangle />
      </div>
    </div>
  );
};
