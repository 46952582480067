import { Link } from "react-router-dom";
import { CollapseIcon } from "../../../../ui/icons/CollapseIcon";
import "./MenuItem.scss";

interface MenuItemProps {
  name: string;
  icon: any;
  collapse?: boolean;
  active?: boolean;
  childrens?: any;
}

export const MenuItem = ({ name, active, icon, childrens }: MenuItemProps) => {
  return (
    <>
      <div
        data-testid="menu-item"
        className={`menu-item ${active ? "menu-item-active" : ""}`}
      >
        <div className="icon">{icon}</div>
        <div className="name">{name}</div>
        <div className={`collapse`}>
          <CollapseIcon width={16} height={7} color="#EAEAEA" />
        </div>
      </div>
      {/* Childrens */}
      {childrens && childrens.length > 0 ? (
        <div className="childrens">
          {childrens.map((item: any, index: number) => (
            <Link to={item.path} key={index}>
              <div className="children">{item.name}</div>
            </Link>
          ))}
        </div>
      ) : (
        ""
      )}
    </>
  );
};
