import React from "react";
import "./Logo.scss";

export const Logo = () => {
  return (
    <div data-testid="logo" className="logo">
      <img src="./logo192.png" alt="logo" />
      <h4 style={{ padding: "0 5px" }}>Poliglota</h4>
    </div>
  );
};
