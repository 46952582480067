import { createSlice } from "@reduxjs/toolkit";

export const lastVisitedSlice = createSlice({
  name: "lastVisited",
  initialState: {
    lastVisited: [],
  },
  reducers: {
    addLastVisited: (state, action) => {
      state.lastVisited = [action.payload, ...state.lastVisited] as any;
    },
  },
});

export const { addLastVisited } = lastVisitedSlice.actions;
export default lastVisitedSlice.reducer;
