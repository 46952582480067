import "./Workers.scss";
import { BoxContainer } from "../../../ui/elements/BoxContainer/BoxContainer";
import { WorkersContainer } from "./WorkersContainer/WorkersContainer";
import "react-calendar/dist/Calendar.css";
import { LastVisitedItem } from "../Dashboard/LastVisitedItem/LastVisitedItem";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

export const Workers = () => {
  const navigate = useNavigate();
  const lastVisited = useSelector(
    (state: any) => state.lastVisited.lastVisited
  );
  return (
    <div data-testid="workers" className="workers">
      {/* Left Side */}
      <div className="left-content-side">
        <h2>Złóż wniosek</h2>

        {/* Workers */}
        <div className="stat-item" style={{ marginLeft: "8px" }}>
          <BoxContainer>
            <WorkersContainer />
          </BoxContainer>
        </div>
      </div>

      {/* Right Menu Widget */}
      <div className="right-menu-widget">
        <BoxContainer>
          <div className="title" style={{ marginTop: "16px" }}>
            Ostatnio przeglądane:
          </div>
          {lastVisited.map((item: any, key: number) => {
            if (key > 9) {
              return null;
            }
            return (
              <div
                onClick={() => {
                  navigate(`/request/${item.applicationId}`);
                }}
              >
                <LastVisitedItem
                  id={item.applicationId}
                  languageId={item.applicationBody.languageId}
                  modifyDate={item.modifyDateTime}
                  firstName={item.applicationBody.employee.firstName}
                  lastName={item.applicationBody.employee.lastName}
                />
              </div>
            );
          })}
        </BoxContainer>
      </div>
    </div>
  );
};
