import "./UserSection.scss";
import { ProfileIcon } from "../../../ui/icons/ProfileIcon";
import { LogoutIcon } from "../../../ui/icons/LogoutIcon";
import { Avatar } from "../../../ui/elements/Avatar/Avatar";
import { useSelector } from "react-redux";

export const UserSection = () => {
  const applicant = useSelector((state: any) => state.applicant);
  const initials =
    applicant.applicant.firstName[0] + applicant.applicant.surname[0];
  return (
    <div data-testid="user-section" className="user-section">
      <Avatar height={48} width={48} initials={initials} theme="avatar-light" />
      <div className="name">
        {applicant.applicant.firstName}{" "}
        <strong>{applicant.applicant.surname}</strong>
      </div>
      <div className="icons">
        <ProfileIcon width={24} height={24} color="#ececec" />
      </div>
    </div>
  );
};
