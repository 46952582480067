import ReactCountryFlag from "react-country-flag";
import { MoreIcon } from "../../../../ui/icons/MoreIcon";
import "./LastVisitedItem.scss";

interface LastVisitedItemProps {
  id: number;
  languageId: string;
  modifyDate?: string;
  firstName?: string;
  lastName?: string;
}

export const LastVisitedItem = ({
  id,
  languageId,
  modifyDate,
  firstName,
  lastName,
}: LastVisitedItemProps) => {
  return (
    <div data-testid="last-visited-item" className="last-visited-item">
      <div className="last-visited-item-content">
        <div className="flag-icon">
          <ReactCountryFlag
            countryCode={languageId}
            svg
            style={{ fontSize: "30px" }}
          />
        </div>
        <div className="last-item-content">
          <div className="content-title">Wniosek - #{id}</div>
          <div className="content-date">{modifyDate}</div>
          <div className="content-name">
            {firstName} {lastName}
          </div>
        </div>
        <div>
          {" "}
          <MoreIcon color="#29313e" width={11} height={24} />
        </div>
      </div>
    </div>
  );
};
