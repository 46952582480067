import { createSlice } from "@reduxjs/toolkit";

export const applicantSlice = createSlice({
  name: "applicant",
  initialState: {
    applicant: {
      applicantId: "POL0081672",
      firstName: "Mirosław",
      surname: "Dresler",
    },
    isGridAdmin: null,
    isSenderToGrid: null,
  },
  reducers: {
    setApplicant: (state, action) => {
      state.applicant = action.payload;
    },
    setGridAdmin: (state, action) => {
      state.isGridAdmin = action.payload;
    },
    setSenderToGrid: (state, action) => {
      state.isSenderToGrid = action.payload;
    },
  },
});

export const { setApplicant, setGridAdmin, setSenderToGrid } =
  applicantSlice.actions;
export default applicantSlice.reducer;
