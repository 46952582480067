import axios from "axios";
import "./HistoryContainer.scss";
import { HistoryItemsLeft } from "./HistoryItems/HistoryItemsLeft";
import { HistoryItemsRight } from "./HistoryItems/HistoryItemsRight";
import { API_URL } from "../../../../env";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHistory } from "../../../../features/app/appSlice";
import { useNavigate } from "react-router";

export const HistoryContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const historyItems = useSelector((state: any) => state.app.history);
  const choosenLocalization = useSelector(
    (state: any) => state.localizations.choosenLocalization
  );

  // odd history items:
  const leftItems = historyItems.filter((item: any, index: any) => {
    return index % 2 === 0;
  });

  // even history items:
  const rightItems = historyItems.filter((item: any, index: any) => {
    return index % 2 !== 0;
  });

  const localizations = useSelector(
    (state: any) => state.localizations.localizations
  );

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (choosenLocalization) {
      const body = {
        auchancenaffcode: localizations[0].auchancenaffcode,
        office: localizations[0].office,
      };
      axios
        .post(API_URL + "/Dashboard/GetDashboardHistory", body)
        .then((res) => {
          dispatch(setHistory(res.data.rows));
        });
    }
  }, [choosenLocalization]);

  return (
    <div data-testid="history-container" className="history-container">
      <div className="title">Historia</div>
      <div className="history">
        <div className="timeline">
          <div className="timeline-container-left">
            {leftItems.map((item: any, index: any) => {
              return (
                <div
                  key={index}
                  className={`left-${index}`}
                  style={{
                    marginTop: `${index === 0 ? 0 : 85}px`,
                  }}
                  onClick={() => {
                    navigate(`/request/${item.applicationId}`);
                  }}
                >
                  <HistoryItemsLeft flag={item.lang} text={item.rowText} />
                </div>
              );
            })}
          </div>
          <div className="timeline-container-right">
            {rightItems.map((item: any, index: any) => {
              return (
                <div
                  key={index}
                  className={`right-${index}`}
                  style={{
                    marginTop: `85px`,
                  }}
                  onClick={() => {
                    navigate(`/request/${item.applicationId}`);
                  }}
                >
                  <HistoryItemsRight text={item.rowText} flag={item.lang} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
