import "./RequestItem.scss";
import { Avatar } from "../../../../../ui/elements/Avatar/Avatar";
import ReactCountryFlag from "react-country-flag";
import { useDispatch } from "react-redux";
import {
  setChoosenRequest,
  setFormMode,
} from "../../../../../features/formRequest/formRequestSlice";
import { showModal } from "../../../../../features/modal/modalSlice";
import { addLastVisited } from "../../../../../features/lastVisited/lastVisitedSlice";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { setStatuses } from "../../../../../features/statuses/statusesSlice";
import { API_URL } from "../../../../../env";
import moment from "moment";

interface RequestItemProps {
  id: number;
  application: any;
}

export const RequestItem = ({ id, application }: RequestItemProps) => {
  const dispatch = useDispatch();
  const statuses = useSelector((state: any) => state.statuses.statuses);
  const applicant = useSelector((state: any) => state.applicant.applicant);


  const status = statuses.filter((status: any) => {
    return status.applicationStatusId === application.applicationStatusId;
  });

  useEffect(() => {
    axios.post(API_URL + "/Statuses/getStatuses", {}).then((res) => {
      dispatch(setStatuses(res.data.statuses));
    });
  }, []);
  return (
    <div
      data-testid="request-item"
      className="request-item"
      onClick={() => {
        dispatch(setChoosenRequest(application));
        dispatch(setFormMode("show"));
        dispatch(addLastVisited(application));
        dispatch(showModal());
      }}
    >
      <div className="number">#{id}</div>
      <div>{moment(application.addDateTime).format("YYYY-MM-DD - HH:MM")}</div>
      <Avatar
        height={35}
        width={35}
        initials={
          application.applicationBody.employee.firstName[0] +
          application.applicationBody.employee.lastName[0]
        }
        theme="avatar-dark"
      />
      <div className="firstname">
        {application.applicationBody.employee.firstName}{" "}
        {application.applicationBody.employee.lastName}
      </div>
      <div className="position">{application.title}</div>
      <div
        className="flag-icon"
        style={{
          display: "flex",
        }}
      >
        <ReactCountryFlag
          countryCode={application.applicationBody.languageId}
          svg
          style={{
            fontSize: "30px",
            marginRight: "10px",
          }}
        />
      </div>

      <div className="e-mail">{application.emp_MAIL}</div>
      <div
        className="badge"
        style={{
          backgroundColor: status[0] ? status[0].color : "#FFFFFF",
        }}
      >
        {status[0] ? status[0].applicationStatusName : ""}
      </div>
      {!applicant.isSenderToGrid && (
        <div>
          <div className="grid-admin-btn-success">
            <button>Przekaż wniosek do akceptacji</button>
          </div>
          <div className="grid-admin-btn-error">
            <button>Odrzuć wniosek </button>
          </div>
        </div>
      )}
    </div>
  );
};
