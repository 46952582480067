import { CloseIcon } from "../../icons/CloseIcon";
import "./Modal.scss";
import { useDispatch } from "react-redux";
import { hideModal } from "../../../features/modal/modalSlice";
import { clearState } from "../../../features/formRequest/formRequestSlice";

interface ModalProps {
  children: React.ReactNode;
  title: string;
}

export const Modal = ({ children, title }: ModalProps) => {
  const dispatch = useDispatch();

  const handleCloseButton = () => {
    dispatch(hideModal());
    dispatch(clearState());
  };

  return (
    <div data-testid="modal" className="modal-overlay">
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title">{title}</div>
          <div className="modal-close" onClick={() => handleCloseButton()}>
            <CloseIcon width={24} height={24} color="black" />
          </div>
        </div>
        <hr />
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};
