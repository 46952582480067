import "./BoxContainer.scss";

interface BoxContainerProps {
  children: React.ReactNode;
}

export const BoxContainer = ({ children }: BoxContainerProps) => {
  return (
    <div data-testid="box-container" className="box-container">
      {children}
    </div>
  );
};
