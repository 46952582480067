import "./Dashboard.scss";
import { BoxContainer } from "../../../ui/elements/BoxContainer/BoxContainer";
import { InfoStat } from "./InfoStat/InfoStat";
import { WorkersContainer } from "./WorkersContainer/WorkersContainer";
import { HistoryContainer } from "./HistoryContainer/HistoryContainer";
import "react-calendar/dist/Calendar.css";

import Calendar from "react-calendar";
import { LastVisitedItem } from "./LastVisitedItem/LastVisitedItem";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import { API_URL } from "../../../env";
import { setDashboardBoxes } from "../../../features/app/appSlice";
import { setStatuses } from "../../../features/statuses/statusesSlice";
import { setApplications } from "../../../features/applications/applicationsSlice";
import { useNavigate } from "react-router";
import { setChoosenLocalization } from "../../../features/localizations/localizationsSlice";
import { setWorkers } from "../../../features/workers/workersSlice";

export const Dashboard = () => {
  const navigate = useNavigate();
  const applicant = useSelector((state: any) => state.applicant.applicant);

  const lastVisited = useSelector(
    (state: any) => state.lastVisited.lastVisited
  );

  const dashboard = useSelector((state: any) => state.app.dashboard);
  const dispatch = useDispatch();

  const localizations = useSelector(
    (state: any) => state.localizations.localizations
  );

  const choosenLocalization = useSelector(
    (state: any) => state.localizations.choosenLocalization
  );

  const setLocalization = (e: any) => {
    const localization = localizations.filter(
      (item: any) => item.auchancenaffcode === e.target.value
    );

    dispatch(setChoosenLocalization(localization[0]));

    const body = {
      applicantPOL: applicant.applicantId,
      localisation: {
        auchancenaffcode: localization[0].auchancenaffcode,
        office: localization[0].office,
      },
    };
    axios.post(API_URL + `/GetEmployeesApplications`, body).then((res) => {
      dispatch(setWorkers(res.data.employeeApplications));
    });
  };

  useEffect(() => {
    axios.post(API_URL + "/Statuses/getStatuses", {}).then((res) => {
      dispatch(setStatuses(res.data.statuses));
    });
    if (choosenLocalization) {
      const body = {
        applicant: applicant.applicantId,
        inProgress: false,
        learningInProgress: false,
        localisation: {
          auchancenaffcode: choosenLocalization.auchancenaffcode,
          office: choosenLocalization.office,
        },
      };

      axios
        .post(API_URL + "/Applications/getApplications", body)
        .then((res) => {
          dispatch(setApplications(res.data.applications));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    if (choosenLocalization) {
      axios
        .post(API_URL + "/Dashboard/GetDashboard", {
          auchancenaffcode: choosenLocalization.auchancenaffcode,
          office: choosenLocalization.office,
        })
        .then((res) => {
          dispatch(setDashboardBoxes(res.data.boxes));
        });
    }
  }, [choosenLocalization]);

  return (
    <div data-testid="dashboard" className="dashboard">
      {/* Left Side */}
      <div className="left-content-side">
        <h2>Dashboard</h2>
        <div className="firt-row-stats">
          {dashboard && dashboard.boxes && dashboard.boxes.length > 0
            ? dashboard.boxes.map((item: any, key: number) => {
                return (
                  <div className="stat-item" key={key}>
                    <BoxContainer>
                      <InfoStat
                        title={item.header}
                        desc={item.description}
                        number={item.mainNumber}
                        progress={item.progress}
                        color={item.color}
                        link={item.link}
                      />
                    </BoxContainer>
                  </div>
                );
              })
            : ""}
        </div>

        {/* Workers */}
        <div className="stat-item" style={{ marginLeft: "8px" }}>
          <BoxContainer>
            <WorkersContainer />
          </BoxContainer>
        </div>

        {/* History */}
        <div
          className="stat-item"
          style={{ marginLeft: "8px", marginTop: "24px" }}
        >
          <BoxContainer>
            <HistoryContainer />
          </BoxContainer>
        </div>
      </div>

      {/* Right Menu Widget */}
      <div className="right-menu-widget">
        <BoxContainer>
          <div className="title">Wybierz sklep</div>
          {/* Lokalizacje */}
          <div>
            <select
              onChange={(e) => {
                setLocalization(e);
              }}
            >
              {localizations.map((item: any, key: number) => {
                return (
                  <option
                    key={key}
                    value={item.auchancenaffcode}
                    defaultChecked={
                      choosenLocalization === item.auchancenaffcode
                    }
                  >
                    {item.office}
                  </option>
                );
              })}
            </select>
          </div>
        </BoxContainer>

        <BoxContainer>
          <Calendar />
          <hr />
          <div className="title" style={{ marginTop: "16px" }}>
            Ostatnio przeglądane
          </div>
          {lastVisited.map((item: any, key: number) => {
            if (key > 9) {
              return null;
            }
            return (
              <div
                key={key}
                onClick={() => {
                  navigate(`/request/${item.applicationId}`);
                }}
              >
                <LastVisitedItem
                  id={item.applicationId}
                  languageId={item.applicationBody.languageId}
                  modifyDate={item.modifyDateTime}
                  firstName={item.applicationBody.employee.firstName}
                  lastName={item.applicationBody.employee.lastName}
                />
              </div>
            );
          })}
        </BoxContainer>
      </div>
    </div>
  );
};
