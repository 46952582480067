interface MoreIconProps {
  width: number;
  height: number;
  color: string;
}

export const MoreIcon = ({ width, height, color }: MoreIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.75 4.5C2.84836 4.4988 2.94589 4.5182 3.03631 4.55695C3.12672 4.5957 3.20803 4.65294 3.275 4.725L10.025 11.475C10.325 11.775 10.325 12.24 10.025 12.54L3.275 19.275C2.975 19.575 2.51 19.575 2.21 19.275C1.91 18.975 1.91 18.51 2.21 18.21L8.435 12L2.21 5.775C1.91 5.475 1.91 5.01 2.21 4.71C2.36 4.56 2.555 4.485 2.735 4.485L2.75 4.5Z"
        fill={color}
      />
    </svg>
  );
};
