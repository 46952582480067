import ReactCountryFlag from "react-country-flag";
import { Avatar } from "../../../../../ui/elements/Avatar/Avatar";
import "./WorkerItem.scss";
import { AddIcon } from "../../../../../ui/icons/AddIcon";

import { useDispatch } from "react-redux";
import { showModal } from "../../../../../features/modal/modalSlice";
import {
  setEmployee,
  setEmployeeXPL,
  setFormMode,
} from "../../../../../features/formRequest/formRequestSlice";
import { clearState } from "../../../../../features/formRequest/formRequestSlice";
import { useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";

interface WorkerItemProps {
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  applications: any[];
  mail: string;
  level: number;
  childrens: any[];
  collapsed?: boolean;
  info: string;
  info2: string;
}

export const WorkerItem = ({
  firstName,
  lastName,
  title,
  applications,
  id,
  mail,
  level,
  childrens,
  collapsed,
  info,
  info2,
}: WorkerItemProps) => {
  const dispatch = useDispatch();

  const handleAddRequest = () => {
    dispatch(setFormMode("new"));
    dispatch(clearState());
    dispatch(showModal());
    dispatch(setEmployeeXPL(id));
    dispatch(setEmployee({ id: 1, firstName: firstName, lastName: lastName }));
  };

  const [showChildrens, setShowChildrens] = useState(collapsed);
  return (
    <div
      style={{
        borderBottom: "1px solid #eee",
      }}
    >
      <div
        data-testid="worker-item"
        className="worker-item"
        style={{
          position: "relative",
          marginLeft: level * 30 + "px",
          width: "calc(100% - " + level * 30 + "px)",
          cursor: childrens && childrens.length > 0 ? "pointer" : "default",
          borderBottom:
            childrens && childrens.length > 0 ? "none" : "1px solid #eee",
        }}
        onClick={() => {
          setShowChildrens(!showChildrens);
        }}
      >
        {childrens && childrens.length > 0 && (
          <div className="arrow">
            {showChildrens ? (
              <div>
                <AiFillCaretDown />
              </div>
            ) : (
              <div className="reverse">
                <AiFillCaretDown />
              </div>
            )}
          </div>
        )}
        <Avatar
          height={35}
          width={35}
          initials={firstName[0] + lastName[0]}
          theme="avatar-dark"
          backgroundColor={`${
            childrens && childrens.length > 0 ? "#04BC11" : "#29313e"
          }`}
        />
        <div className="firstname">
          {firstName} {lastName} <br />({id})
        </div>
        <div className="position">{title}</div>
        <div>Wnioski: {applications.length} </div>
        <div className="flag-icon">
          {applications.map((application: any) => {
            return (
              <ReactCountryFlag
                countryCode={application.applicationBody.languageId}
                svg
                style={{ fontSize: "30px" }}
              />
            );
          })}
        </div>
        <div className="e-mail">{mail ? mail : ""}</div>
        <div>{info}</div>
        <div>{info2}</div>
        <div className="add-request" onClick={() => handleAddRequest()}>
          {" "}
          <AddIcon width={24} height={24} color="#fff" />{" "}
        </div>
      </div>

      {childrens && childrens.length > 0 && (
        <div
          className={`childrens ${showChildrens ? "childrens-collapsed" : ""}`}
        >
          {childrens.map((children: any, key: number) => {
            return (
              <WorkerItem
                key={key}
                id={children.cn}
                firstName={children.givenname}
                lastName={children.sn}
                title={children.title}
                applications={children.applications}
                mail={children.mail}
                level={level + 1}
                childrens={children.empl}
                collapsed={true}
                info={children.info}
                info2={children.info2}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
