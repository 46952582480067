import "./TopPDF.scss";
import { PDFIcon } from "../../../ui/icons/PDFIcon";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setRulesLink } from "../../../features/app/appSlice";
import { API_URL } from "../../../env";

export const TopPDF = () => {
  const dispatch = useDispatch();
  const rulesLink = useSelector((state: any) => state.app.rulesLink);

  axios.get(API_URL + "/Applications/getLinkInstructions").then((res) => {
    dispatch(setRulesLink(res.data));
  });
  return (
    <a href={rulesLink} target="_blank">
      <div data-testid="top-pdf" className="top-pdf">
        <PDFIcon width={40} height={40} color="#ff0000" />

        <div style={{ marginLeft: "8px" }}>
          Zasady uczestnictwa w kursach językowych
        </div>
      </div>
    </a>
  );
};
