import React from "react";
import "./Toolbar.scss";
import { Avatar } from "../../../ui/elements/Avatar/Avatar";
import { useSelector } from "react-redux";

export const Toolbar = () => {
  // get applicant from redux
  const applicant = useSelector((state: any) => state.applicant);
  const initials =
    applicant.applicant.firstName[0] + applicant.applicant.surname[0];

  return (
    <div data-testid="toolbar" className="toolbar">
      {/* <div className="icon">
        <NotyficationIcon width={25} height={25} color="#29313E" />
        <div className="notyfications-background">2</div>
      </div> */}

      <div className="icon">
        <Avatar
          width={35}
          height={35}
          theme="avatar-dark"
          initials={initials || "-"}
        />
      </div>
    </div>
  );
};
