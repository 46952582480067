import { DecelineItem } from "./DecelineItem/DecelineItem";
import { FormRequest } from "./FormRequest/FormRequest";
import { LeftItem } from "./LeftItem/LeftItem";
import "./NewRequestModal.scss";
import { useSelector } from "react-redux";

export const NewRequestModal = () => {
  const formRequestState = useSelector(
    (state: any) => state.formRequest.formRequest
  );

  const formMode = useSelector((state: any) => state.formRequest.formMode);

  const choosenRequest = useSelector(
    (state: any) => state.formRequest.choosenRequest
  );

  const statuses = useSelector((state: any) => state.statuses.statuses);

  const getStatusNameFromId = (id: number) => {
    const status = statuses.find(
      (status: any) => status.applicationStatusId === id
    );

    return status.applicationStatusName;
  };

  const getPercentageFilled = () => {
    let filled = 0;
    if (formRequestState.languageId !== null) {
      filled += 10;
    }
    if (formRequestState.isContinuation !== null) {
      filled += 15;
    }
    if (formRequestState.learningModeId !== null) {
      filled += 15;
    }
    if (formRequestState.sendedBefore !== null) {
      filled += 15;
    }
    if (formRequestState.startDate !== null) {
      filled += 15;
    }
    if (formRequestState.buisnessClarification !== null) {
      filled += 15;
    }
    if (formRequestState.level !== null) {
      filled += 15;
    }

    return filled;
  };
  return (
    <div data-testid="new-request-modal" className="new-request-modal">
      <div className="left-side">
        <LeftItem
          title="Formularz zgłoszenia"
          secondLine={`${
            formMode === "new" ? getPercentageFilled() + "%" : "100%"
          }`}
          color={`
          ${
            getPercentageFilled() === 100 || formMode === "show"
              ? "#00FF00"
              : "#FF0000"
          }`}
          disabled={formMode === "new" ? false : true}
        />
        <LeftItem
          title="Wniosek złożony"
          secondLine={
            formMode === "show" && choosenRequest !== null
              ? getStatusNameFromId(choosenRequest.applicationStatusId)
              : ""
          }
          disabled={formMode === "show" ? false : true}
          date={formMode === "show" ? choosenRequest.addDateTime : ""}
        />
        <LeftItem title="Status wniosku" disabled={true} />
        <DecelineItem />
      </div>
      <div className="right-side">
        <FormRequest choosenRequest={choosenRequest} />
      </div>
    </div>
  );
};
