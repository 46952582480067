import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formMode: "new",
  choosenRequest: null,
  formRequest: {
    employee: {
      id: null,
      firstName: null,
      lastName: null,
    },
    languageId: null,
    level: null,
    sendedBefore: null,
    startDate: null,
    comments: null,
    suggestions: null,
    preferences: null,
    isContinuation: null,
    learningModeId: null,
    buisnessClarification: null,
    bussinessClarificationText: null,
    learnHistory: null,
  },
  employee: null,
  applicant: null,
};

export const formRequestSlice = createSlice({
  name: "formRequest",
  initialState: initialState,
  reducers: {
    setFormRequest: (state, action) => {
      state.formRequest = action.payload;
    },
    setLanguageId: (state, action) => {
      state.formRequest.languageId = action.payload;
    },
    setLanguageLevel: (state, action) => {
      state.formRequest.level = action.payload;
    },
    setSendedBefore: (state, action) => {
      state.formRequest.sendedBefore = action.payload;
    },
    setStartDate: (state, action) => {
      state.formRequest.startDate = action.payload;
    },
    setEmployee: (state, action) => {
      state.formRequest.employee = action.payload;
    },
    setComments: (state, action) => {
      state.formRequest.comments = action.payload;
    },
    setSuggestions: (state, action) => {
      state.formRequest.suggestions = action.payload;
    },
    setPreferences: (state, action) => {
      state.formRequest.preferences = action.payload;
    },
    setChoosenRequest: (state, action) => {
      state.choosenRequest = action.payload;
    },
    setFormMode: (state, action) => {
      state.formMode = action.payload;
    },
    setLearningModeId: (state, action) => {
      state.formRequest.learningModeId = action.payload;
    },
    setContinuation: (state, action) => {
      state.formRequest.isContinuation = action.payload;
    },
    setBussinessClarification: (state, action) => {
      state.formRequest.buisnessClarification = action.payload;
    },
    setBussinessClarificationText: (state, action) => {
      state.formRequest.bussinessClarificationText = action.payload;
    },
    clearState: (state) => {
      return (state = initialState);
    },
    setEmployeeXPL: (state, action) => {
      state.employee = action.payload;
    },
    setApplicantXPL: (state, action) => {
      state.applicant = action.payload;
    },
    setLearnHistory: (state, action) => {
      state.formRequest.learnHistory = action.payload;
    },
    setLevel: (state, action) => {
      state.formRequest.level = action.payload;
    },
  },
});

export const {
  setFormRequest,
  setLanguageId,
  setLanguageLevel,
  setSendedBefore,
  setStartDate,
  setEmployee,
  clearState,
  setComments,
  setFormMode,
  setSuggestions,
  setPreferences,
  setChoosenRequest,
  setEmployeeXPL,
  setApplicantXPL,
  setLearningModeId,
  setContinuation,
  setBussinessClarification,
  setBussinessClarificationText,
  setLearnHistory,
  setLevel,
} = formRequestSlice.actions;
export default formRequestSlice.reducer;
