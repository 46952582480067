import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/store";
import { Provider } from "react-redux";

import {
  createBrowserRouter,
  RouterProvider,
  createHashRouter,
} from "react-router-dom";
import { Workers } from "./components/Content/Workers/Workers";
import { Dashboard } from "./components/Content/Dashboard/Dashboard";
import { Requests } from "./components/Content/Requests/Requests";
import { Grid } from "./components/Content/Grid/grid";

const router = createHashRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/workers",
        element: <Workers />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/requests",
        element: <Requests />,
      },
      {
        path: "/request/:requestId",
        element: <Requests />,
      },
      // TODO: stwórz możliwość wczytywania wniosku po id
      {
        path: "/grid/:reportId",
        element: <Grid />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
