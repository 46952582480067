import ReactCountryFlag from "react-country-flag";
import "./HistoryItemsLeft.scss";
import { ItemTriangle } from "./ItemTriangle";

interface PropsInterface {
  text: string;
  flag: string;
}

export const HistoryItemsLeft = ({ text, flag }: PropsInterface) => {
  return (
    <div data-testid="history-item-left" className="history-item-left">
      <div className="timeline-item">
        <div>
          <div className="timeline-item-content">
            <div className="circle-lang">
              <ReactCountryFlag
                countryCode={flag}
                style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                svg
              />
            </div>
            <div dangerouslySetInnerHTML={{ __html: text }}></div>
          </div>
        </div>
      </div>
      <div className="timeline-dot"></div>
      <div className="item-triangle">
        <ItemTriangle />
      </div>
    </div>
  );
};
