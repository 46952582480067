import { useDispatch, useSelector } from "react-redux";
import { BoxContainer } from "../../../ui/elements/BoxContainer/BoxContainer";
import { LastVisitedItem } from "../Dashboard/LastVisitedItem/LastVisitedItem";
import "./Requests.scss";
import { RequestsContainer } from "./RequestsContainer/RequestsContainer";
import { useParams } from "react-router";
import { showModal } from "../../../features/modal/modalSlice";
import {
  setChoosenRequest,
  setFormMode,
} from "../../../features/formRequest/formRequestSlice";
import { useEffect } from "react";
import { setStatuses } from "../../../features/statuses/statusesSlice";
import axios from "axios";
import { API_URL } from "../../../env";

export const Requests = () => {
  const lastVisited = useSelector(
    (state: any) => state.lastVisited.lastVisited
  );

  const applications = useSelector(
    (state: any) => state.applications.applications
  );
  const statuses = useSelector((state: any) => state.statuses.statuses);

  const dispatch = useDispatch();
  const { requestId } = useParams();

  if (requestId) {
    // search in application
    const application = applications.filter((application: any) => {
      return application.applicationId === parseInt(requestId);
    });
    if (statuses.length > 0) {
      dispatch(setChoosenRequest(application[0]));
      dispatch(setFormMode("show"));
      dispatch(showModal());
    }
  }

  useEffect(() => {
    axios.post(API_URL + "/Statuses/getStatuses", {}).then((res) => {
      dispatch(setStatuses(res.data.statuses));
    });
  }, []);
  return (
    <div data-testid="requests" className="requests">
      {/* Left Side */}
      <div className="left-content-side">
        <h2>Lista Wniosków</h2>

        {/* Requests */}
        <div className="stat-item" style={{ marginLeft: "8px" }}>
          <BoxContainer>
            <RequestsContainer />
          </BoxContainer>
        </div>
      </div>

      {/* Right Menu Widget */}
      <div className="right-menu-widget">
        <BoxContainer>
          <div className="title" style={{ marginTop: "16px" }}>
            Ostatnio przeglądane:
          </div>
          {lastVisited.map((item: any, key: number) => {
            if (key > 9) {
              return null;
            }
            return (
              <LastVisitedItem
                id={item.applicationId}
                languageId={item.applicationBody.languageId}
                modifyDate={item.modifyDateTime}
                firstName={item.applicationBody.employee.firstName}
                lastName={item.applicationBody.employee.lastName}
              />
            );
          })}
        </BoxContainer>
      </div>
    </div>
  );
};
