interface CollapseIconProps {
  color: string;
  width: number;
  height: number;
}

export const CollapseIcon = ({ color, width, height }: CollapseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.50001C8.06558 0.49921 8.13059 0.512143 8.19087 0.537976C8.25115 0.56381 8.30535 0.601973 8.35 0.65001L12.85 5.15001C13.05 5.35001 13.05 5.66001 12.85 5.86001C12.65 6.06001 12.34 6.06001 12.14 5.86001L7.99 1.71001L3.85 5.85001C3.65 6.05001 3.34 6.05001 3.14 5.85001C2.94 5.65001 2.94 5.34001 3.14 5.14001L7.64 0.640009C7.74 0.540009 7.87 0.490009 7.99 0.490009L8 0.50001Z"
        fill={color}
      />
    </svg>
  );
};
