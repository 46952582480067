import { CancelIcon } from "../../../../../ui/icons/CancelIcon";
import { MoreIcon } from "../../../../../ui/icons/MoreIcon";
import { ItemTriangle } from "../../HistoryContainer/HistoryItems/ItemTriangle";
import "./DecelineItem.scss";

export const DecelineItem = () => {
  return (
    <div
      data-testid="deceline-item"
      className="deceline-item left-item-disabled"
    >
      <div className="item-icon">
        <CancelIcon width={40} height={40} color="#CC0000" />
      </div>
      <div className="item-informations">
        <div className="item-title">Anulacja zgłoszenia</div>
        <div className="item-second-line"></div>
        <div className="item-date"></div>
      </div>
      <div>
        <MoreIcon color="#000" width={11} height={24} />
      </div>

      <div className="timeline-dot"></div>
      <div className="item-triangle">
        <ItemTriangle />
      </div>
    </div>
  );
};
