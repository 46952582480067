import axios from "axios";
import { RequestItem } from "./RequestItem/RequestItem";
import { API_URL } from "../../../../env";
import { useDispatch } from "react-redux";
import { setApplications } from "./../../../../features/applications/applicationsSlice";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

export const RequestsContainer = () => {
  const dispatch = useDispatch();

  const applicationState = useSelector((state: any) => state.applications);
  const applicant = useSelector((state: any) => state.applicant.applicant);

  const { search } = useLocation();
  const status = new URLSearchParams(search).get("status");

  const choosenLocalization = useSelector(
    (state: any) => state.localizations.choosenLocalization
  );

  // API CALL
  useEffect(() => {
    if (choosenLocalization) {
      const body = {
        applicant: applicant.applicantId,
        inProgress: status === "InProgress" ? true : false,
        learningInProgress: status === "LearningInProgress" ? true : false,
        localisation: {
          auchancenaffcode: choosenLocalization.auchancenaffcode,
          office: choosenLocalization.office,
        },
      };

      axios
        .post(API_URL + "/Applications/getApplications", body)
        .then((res) => {
          dispatch(setApplications(res.data.applications));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [choosenLocalization]);
  return (
    <div data-testid="requests-container" className="requests-container">
      <div className="title">Wnioski</div>
      <div className="requests-list">
        {applicationState && applicationState.applications.length > 0
          ? applicationState.applications.map((application: any) => {
              return (
                <RequestItem
                  id={application.applicationId}
                  application={application}
                />
              );
            })
          : ""}
      </div>
    </div>
  );
};
