import { CheckIcon } from "../../../../../ui/icons/CheckIcon";
import { MoreIcon } from "../../../../../ui/icons/MoreIcon";
import { ItemTriangle } from "../../HistoryContainer/HistoryItems/ItemTriangle";
import "./LeftItem.scss";

interface LeftItemProps {
  title?: string;
  secondLine?: string;
  date?: string;
  color?: string;
  disabled?: boolean;
}

export const LeftItem = ({
  title,
  secondLine,
  color = "#000",
  disabled = false,
  date,
}: LeftItemProps) => {
  return (
    <div
      data-testid="left-item"
      className={`left-item ${disabled ? "left-item-disabled" : ""}`}
    >
      <div className="item-icon">
        <CheckIcon width={40} height={40} color={color} />
      </div>
      <div className="item-informations">
        <div className="item-title">{title}</div>
        <div style={{ color: color }} className="item-second-line">
          {secondLine}
        </div>
        <div className="item-date">{date}</div>
      </div>
      <div>
        <MoreIcon color="#000" width={11} height={24} />
      </div>

      <div className="timeline-dot"></div>
      <div className="item-triangle">
        <ItemTriangle />
      </div>
    </div>
  );
};
